import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Image from "./Image";

import logoFacebook from "../assets/new-imgs/logo-facebook.svg";
import logoInstagram from "../assets/new-imgs/logo-instagram.svg";
import { axiosInstance } from "../libries";
import { updateSocials } from "../store/slices/cacheSlice";

const Footer = () => {
  const dispatch = useDispatch()
  const { categories, orders, socials } = useSelector((state) => state.cache);

  useEffect(() => {
    axiosInstance()
    .get(`/get-social-handle`)
    .then((resp) => {
      const socialResp = resp.data.data.handle.data;

      dispatch(updateSocials(socialResp))
      
    })
    .catch((_err) => {})
  }, [dispatch])

  return (
    <>
      <footer className="footer1">
        <div className="container-sm footer1__container">
          <div className="footer1__block">
            <h3 className="footer1__block-heading">Quick Links</h3>
            <div className="footer1__flex quick">
              <ul className="footer1__navbar flex__cat">
                {categories?.slice(0, 10).map((cat) => (
                  <li key={cat.id}>
                    <Link
                      to={`/categories?categories=${cat.id}`}
                      className="footer1__link"
                    >
                      {cat.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer1__block">
            <h3 className="footer1__block-heading">Help</h3>
            <div className="footer1__flex">
              <ul className="footer1__navbar">
                <li>
                  <Link to="/faq" className="footer1__link">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="footer1__link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link id="open_live_chat" className="footer1__link">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/refund-policy" className="footer1__link">
                    Refund Policy
                  </Link>
                </li>
              </ul>
              <ul className="footer1__navbar">
                <li>
                  <Link to="/terms-condition" className="footer1__link">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="footer1__link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer1__block">
            <h3 className="footer1__block-heading">Customer Service</h3>
            <div className="footer1__contacts">
              {orders?.map((order) => (
                <div className="footer1__contact" key={order.id}>
                  <Image src={order.image_url} alt={order.name} />
                  {order.name}
                </div>
              ))}
            </div>
          </div>
          <div className="footer1__block">
            <h3 className="footer1__block-heading">Social Media</h3>
            {socials ? <div className="footer1__socials">
              <Link
                to={`http://www.fb.com/${socials?.facebook}`}
                className="footer1__social"
                target="_blank"
                aria-label="facebook"
              >
                <img src={logoFacebook} alt="facebook" />
              </Link>
              <Link
                to={`http://www.instagram.com/${socials?.instagram}`}
                className="footer1__social"
                target="_blank"
                aria-label="instagram"
              >
                <img src={logoInstagram} alt="instagram" />
              </Link>
              <Link
                to={`https://x.com/${socials?.x}`}
                className="footer1__social"
                target="_blank"
                aria-label="twitter"
              >
                <img
                  src="/images/twitter.svg"
                  alt="twitter"
                  className="x-twitter"
                />
              </Link>
            </div> : null}
          </div>
        </div>
      </footer>
      <div className="copyright">
        © {new Date().getFullYear()} ShopNig Mall - All Rights Reserved
      </div>
    </>
  );
};

export default Footer;
